.game-chat {
  margin-bottom: 20px;

  h4 {
    text-align: center;
  }
}

#chat-entries {
  overflow-x: hidden;
  overflow-y: scroll;
  height: 150px;
  background: #eeeeee;
}

.chat-message {
  padding-left: 20px;
  padding-right: 0px;
  padding-top: 7px;
  font-size: 10px;
  word-break: break-word;
}

.chat-text {
  float: left;
  width: 78% !important;
}

.chat-submit {
  float: right;
}

.chat-entry img {
  width: 32px;
}
