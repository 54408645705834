.guess-options {
  border: 1px dashed blue;
  margin: 10px auto;
  text-align: center;
  padding: 10px;
}

.guess-option {
  display: inline-block;
  cursor: pointer;
  margin: 10;
  padding: 10;
  font-size: 30px;
  width: 33px;
  border: 1px dashed gray;
}
