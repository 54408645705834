.trump-card {
  text-align: center;
  margin-bottom: 20px;

  svg {
    margin: auto;
    width: 100px;
  }
}

.game-winner {
  text-align: center;
  margin-bottom: 20px;

  img {
    margin: auto;
    width: 100px;
  }
}

.btn.back-to-lobby {
  margin-top: 50px;
}
