.status-bar {
  font-size: 12px;
  background-color: #efefef;
  padding: 5px 20px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-bottom: 10px;

  .player-name {
    font-weight: 700;
  }

  img,
  svg {
    width: 32px !important;
    margin-left: 5px;
  }

  &.your-turn {
    background-color: lightpink;
    font-style: italic;
    animation: blink-animation 2s steps(5, start) infinite;
  }
}

@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
