.waiting-to-start {
  .player-name {
    font-weight: 700;
  }

  img {
    width: 100px;
  }

  .player {
    text-align: center;
  }

  .no-players,
  .players {
    margin-bottom: 20px;
  }

  .status-bar {
    margin-top: 20px;
  }
}

.available-avatars .avatar {
  width: 100px;
  cursor: pointer;
}
