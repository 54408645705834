.deg-0 {
  transform: translate(140px);
}
.deg-0-outer {
  transform: translate(250px);
}
.deg-30 {
  transform: rotate(30deg) translate(140px) rotate(-90deg);
}
.deg-30-outer {
  transform: rotate(30deg) translate(240px) rotate(-90deg);
}
.deg-60 {
  transform: rotate(60deg) translate(140px) rotate(-90deg);
}
.deg-60-outer {
  transform: rotate(60deg) translate(240px) rotate(-90deg);
}
.deg-90 {
  transform: rotate(90deg) translate(140px) rotate(-90deg);
}
.deg-90-outer {
  transform: rotate(90deg) translate(240px) rotate(-90deg);
}
.deg-120 {
  transform: rotate(120deg) translate(140px) rotate(-90deg);
}
.deg-120-outer {
  transform: rotate(120deg) translate(240px) rotate(-90deg);
}
.deg-150 {
  transform: rotate(150deg) translate(140px) rotate(-90deg);
}
.deg-150-outer {
  transform: rotate(150deg) translate(240px) rotate(-90deg);
}
.deg-180 {
  transform: translate(-140px);
}
.deg-180-outer {
  transform: translate(-240px);
}
.deg-210 {
  transform: rotate(210deg) translate(140px) rotate(-270deg);
}
.deg-210-outer {
  transform: rotate(210deg) translate(285px) rotate(-270deg);
}
.deg-240 {
  transform: rotate(240deg) translate(140px) rotate(-270deg);
}
.deg-240-outer {
  transform: rotate(240deg) translate(285px) rotate(-270deg);
}
.deg-270 {
  transform: rotate(270deg) translate(140px) rotate(-270deg);
}
.deg-270-outer {
  transform: rotate(270deg) translate(285px) rotate(-270deg);
}
.deg-300 {
  transform: rotate(300deg) translate(140px) rotate(-270deg);
}
.deg-300-outer {
  transform: rotate(300deg) translate(285px) rotate(-270deg);
}
.deg-330 {
  transform: rotate(330deg) translate(140px) rotate(-270deg);
}
.deg-330-outer {
  transform: rotate(330deg) translate(285px) rotate(-270deg);
}

.circle-container {
  position: relative;
  width: 400px;
  height: 400px;
  padding: 20px;
  border: dashed 1px;
  border-radius: 50%;
  margin: 110px auto 125px;
  background: white;
  background-image: linear-gradient(90deg, rgba(200, 0, 0, 0.5) 50%, transparent 0),
    linear-gradient(rgba(200, 0, 0, 0.5) 50%, transparent 0);
  background-size: 30px 30px;

  .avatar {
    width: 100px;
    height: 135px;
  }

  svg {
    display: block;
  }

  .center {
    margin: 156px auto;
    text-align: center;
  }

  .player-name {
    text-align: center;
    margin-top: -20px;
  }

  .card-display {
    text-align: center;
    font-size: 60px;

    .game-card {
      margin-left: 20px;
    }
  }

  .card-display,
  .avatar {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -50px;
  }
}

.actual-badge {
  display: block;
  border-radius: 10px;
  color: white;
  width: 50px;
  text-align: center;
  margin: auto;
  background-color: lightgray;

  &.correct {
    background-color: green;
  }

  &.wrong {
    background-color: red;
  }
}

.guess-card {
  background-color: white;
  border: 1px solid gray;
  width: 69px;
  height: 100px;
  margin-left: 15px;

  &.correct {
    color: white;
    background-color: green;
  }

  &.wrong {
    color: red;
  }
}

.lost-hand .avatar img {
  filter: grayscale(100%) blur(1px);
  opacity: 0.5;
}

.countdown {
  margin-top: -25px;
}

.won-hand .avatar {
  border: 5px green dashed;
  border-radius: 100px;
  background-color: lightgreen;
}

.waiting .avatar {
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
