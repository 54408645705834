.my-hand {
  margin: 0 auto;
  text-align: center;
  padding: 10px 0;
}

.my-hand .hand-card {
  display: inline-block;
  margin-right: 5px;
}

.my-hand.my-turn .hand-card.is-allowed {
  cursor: pointer;
}

.my-hand.my-turn .hand-card.not-allowed {
  cursor: not-allowed;
  opacity: 0.5;
}

.my-hand.waiting .hand-card {
  cursor: not-allowed;
  opacity: 0.5;
}

.my-hand .hand-card svg {
  width: 69px;
}
