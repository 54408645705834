.games-not-completed {
  img,
  svg {
    width: 32px;
  }

  .player {
    padding-right: 5px;
  }

  margin-bottom: 20px;
}
