.rounds-table th,
.rounds-table td {
  text-align: center;
}

.rounds-table th img {
  width: 50px;
}

.total-row td {
  background-color: lightpink;
}

.ranking-row td {
  background-color: azure;
  font-weight: 700;
}

.leaderboard-row.active-round td {
  background-color: aquamarine;
}

.leaderboard-row td.wrong {
  color: red;
  text-decoration: line-through;
}

.leaderboard-row td.correct span {
  color: white;
  background: green;
  border-radius: 50%;
  padding: 5px 10px;
}
